<template>
  <!-- 人员列表 -->
  <section class="tableBox">
    <ul class="table">
      <li class="thead">
        <span>姓名</span>
        <span>电话</span>
        <span>机构</span>
        <!-- <span>在岗</span>
        <span>回国人数</span>
        <span>隔离</span> -->
      </li>
      <li
        class="th"
        v-for="item in list"
        @click="redirect(item)"
        :key="item.id"
      >
        <span>{{ item.name }}</span>
        <span>{{ item.mobile }}</span>
        <span>{{ item.originInstitution||item.institution }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
import { returnWorkList } from "@/api/api";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    async getData(query) {

      let res = await returnWorkList(query);
      
    },
    init() {
      let params = this.$route.query;
      this.t = params.type;
      this.list = JSON.parse(this.$route.query.list)
      // if (this.t == "project") {
      //   this.getData({ project: params.name,flag:this.$route.query.flag });
      // } else if (this.t == "country") {
        
      //   this.getData({ country: params.name ,flag:this.$route.query.flag});
      // } else if (this.t == "organization") {
      //   this.getData({ institution: params.name,flag:this.$route.query.flag});
      // } else {
      //   this.getData({flag:this.$route.query.flag});
      // }
    },
    redirect(item){
      this.$router.push('/person/detail?mobile='+ encodeURIComponent(item.mobile))
    }
  },
};
</script>

<style scoped>
.tableBox {
  padding: 0.1rem;
}
.tableBox h5 {
  color: #999999;
  font-size: 0.16rem;
  margin-bottom: 0.1rem;
}
.table {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.table li {
  display: flex;
  justify-content: space-between;
  font-size: 0.13rem;
  color: #707071;
  line-height: 0.35rem;
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
}
.table li span {
  flex: 1;
  text-align: center;
}
.table li.thead {
  background: rgba(79, 119, 170, 0.24);
  line-height: 0.4rem;
}
</style>